import React from 'react'
import LoginComp from '../components/login.js'

export default props => {
    let { state } = props.location

    let redirectTo = '/'

    if (props.location && props.location.search) {
        const q = new URLSearchParams(props.location.search)
        const rTo = q.get('redirectTo')
        if (rTo) redirectTo = rTo
    }
    console.log(redirectTo)

    if (!state || !state.redirectTo) state = { redirectTo }

    return (
        <div>
            {state && state.message && <p className="text-center text-lg py-2">{state.message}</p>}
            <LoginComp redirectTo={redirectTo} />
        </div>
    )
}
