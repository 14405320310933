import React from 'react'
import { Link, navigate } from 'gatsby'
import { FirebaseContext } from 'gatsby-plugin-firebase'
import googleLogo from '../images/google-logo.svg'

const parseErrorCode = code => {
    switch (code) {
        case 'auth/wrong-password':
            return 'Invalid email or password'
        case 'auth/user-not-found':
            return 'Invalid email or password'
        default:
            return 'Some error happened while logging in'
    }
}

export default props => {
    const { redirectTo, onSuccess } = props
    const fb = React.useContext(FirebaseContext)

    const [submitting, setSubmitting] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState('')

    const handleLoginWithGoogle = e => {
        e.preventDefault()

        if (!fb) return

        const provider = new fb.auth.GoogleAuthProvider()
        fb.auth()
            .signInWithPopup(provider)
            .then(user => {
                onSuccess && onSuccess()
                redirectTo && navigate(redirectTo)
            })
            .catch(err => {
                console.log('Got erro', err)
            })
    }
    const handleInput = e => {
        setError(null)
        if (e.target.name === 'email') setEmail(e.target.value)
        if (e.target.name === 'password') setPassword(e.target.value)
    }

    const handleEmailLogin = e => {
        if (!fb) return
        setError(null)
        e.preventDefault()
        setSubmitting(true)
        console.log(email, password)
        // setTimeout(() => {
        //     setSubmitting(false)
        //     setError('Invalid email or password')
        // }, 2000)

        fb.auth()
            .signInWithEmailAndPassword(email, password)
            .then(user => {
                onSuccess && onSuccess()
                redirectTo && navigate(redirectTo)
            })
            .catch(function(error) {
                setError(parseErrorCode(error.code))
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <div className="fixed inset-0 min-h-screen flex-center bg-gray-50">
            <button
                aria-label="Back"
                onClick={() => window.history.back()}
                className="bg-gray-100 hover:bg-gray-200 focus:outline-none focus:shadow-outline focus:bg-gray-200 rounded-full text-gray-600 fixed inset-y-0 h-10 w-10 p-2 ml-4 mt-4 left-0 flex-center transform duration-100 scale-100 active:scale-95">
                <svg
                    className="h-full w-full"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                </svg>
            </button>
            <div className="max-w-md w-full py-12 px-6">
                <Link to="/" className="group flex-center text-gray-900">
                    <svg
                        className="mr-1 w-8 h-8 group-hover:text-teal-600 transform duration-150 group-hover:scale-110"
                        viewBox="0 0 24 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.061512 0.740892L11.5615 21.7596C11.7373 22.0801 12.2627 22.0801 12.4385 21.7596L23.9385 0.740892C24.0234 0.58597 24.0205 0.397326 23.9297 0.245335C23.8398 0.0933437 23.6767 0 23.5 0L0.499989 0C0.323231 0 0.160145 0.0933437 0.0703011 0.245335C-0.0205192 0.397326 -0.0234489 0.58597 0.061512 0.740892ZM16.9062 10.5L7.09374 10.5L12 1.54102L16.9062 10.5Z"
                            fill="currentColor"
                        />
                    </svg>
                    <span className="text-2xl font-medium leading-5">Indie Nuggets</span>
                </Link>
                <p className="mt-6 text-sm leading-5 text-center text-gray-900">
                    Log in or create your account
                </p>
                <div className="mt-6">
                    <div className="mb-6">
                        <button
                            onClick={handleLoginWithGoogle}
                            className="flex justify-center items-center w-full text-center py-2 px-3 border border-gray-300 rounded-md text-gray-900 font-medium hover:border-gray-400 focus:shadow-outline focus:outline-none focus:border-gray-400 sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95">
                            <img alt="Google" className="w-5 h-5" src={googleLogo} />
                            <span className="ml-2">Log In with Google</span>
                        </button>
                    </div>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center sm:text-sm leading-5">
                            <span className="px-2 bg-gray-50 text-gray-500">
                                Or continue with Email
                            </span>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleEmailLogin}>
                    <div className="mt-6 rounded-md shadow-sm">
                        <div>
                            <input
                                aria-label="Email address"
                                name="email"
                                type="email"
                                value={email}
                                onChange={handleInput}
                                required=""
                                className="border-gray-300 placeholder-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                                placeholder="Email address"
                            />
                        </div>
                        <div className="-mt-px relative">
                            <input
                                aria-label="Password"
                                name="password"
                                type="password"
                                value={password}
                                onChange={handleInput}
                                required=""
                                className="border-gray-300 placeholder-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                                placeholder="Password"
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                <a href="/password-reset" className="text-gray-900 underline">
                                    Forgot?
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <button
                            className={`${
                                submitting ? 'opacity-50 pointer-events-none' : 'hover:bg-gray-600'
                            } 
                            ${error ? 'shake' : ''}
relative block w-full py-2 px-3 border border-transparent rounded-md text-white font-semibold bg-gray-800 hover:bg-gray-700 focus:bg-gray-900 focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95
                                    `}
                            type="submit">
                            <span className="absolute left-0 inset-y pl-3">
                                <svg
                                    className="h-5 w-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20">
                                    <path
                                        fillRule="evenodd"
                                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </span>

                            <span className={`${submitting ? 'opacity-0' : ''} `}>Log in</span>
                            <span
                                className={`${
                                    submitting ? 'opacity-100' : 'opacity-0'
                                } absolute inset-0 flex items-center justify-center pointer-events-none `}>
                                <svg className="h-8 w-8 spin" viewBox="0 0 24 24">
                                    <path
                                        className="text-gray-600"
                                        fill="currentColor"
                                        d="M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z"
                                    />
                                    <path
                                        className="text-gray-400"
                                        fill="currentColor"
                                        d="M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z"
                                    />
                                </svg>
                            </span>
                        </button>
                    </div>
                    <p
                        className={`my-2 py-2 px-4 duration-75 ${
                            error ? 'opacity-1000' : 'opacity-0'
                        } transition-opacity text-center text-red-700 rounded bg-red-100 font-medium text-sm`}>
                        {error}
                    </p>
                </form>
            </div>
        </div>
    )
}
